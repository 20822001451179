import { Component } from '@angular/core';

@Component({
    selector: 'app-historic',
    templateUrl: './historic.component.html',
    styleUrls: ['./historic.component.scss'],
    standalone: false
})
export class HistoricComponent {

}
