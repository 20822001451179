import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class CustomConfirmationService {
  constructor(private confirmationService: ConfirmationService) {}

  confirm(options: {
    message: string;
    target?: EventTarget;
    acceptCallback?: () => Promise<void> | void;
    rejectCallback?: () => void;
  }): void {
    this.confirmationService.confirm({
      target: options.target,
      message: options.message,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonProps: {
        label: 'Confirmar',
        icon: 'pi pi-check',
      },
      rejectButtonProps: {
        label: 'Cancelar',
        icon: 'pi pi-times',
        outlined: true,
      },
      accept: options.acceptCallback,
      reject: options.rejectCallback,
    });
  }
}
