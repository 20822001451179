{
  "name": "colapp-dashboard",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.5",
    "@angular/common": "^19.0.5",
    "@angular/compiler": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/forms": "^19.0.5",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/router": "^19.0.5",
    "@primeng/themes": "^19.0.1",
    "chart.js": "4.4.1",
    "primeicons": "7.0.0",
    "primeng": "^19.0.1",
    "rxjs": "7.8.1",
    "tslib": "^2.3.0",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular/cli": "~19.0.6",
    "@angular/compiler-cli": "^19.0.5",
    "@types/jasmine": "5.1.4",
    "jasmine-core": "5.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "typescript": "5.5.2"
  }
}
