import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthConstants } from './../../config/auth-constants';
import { LocalStorageService } from './../storage/local/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token$ = new BehaviorSubject<any>('');

  constructor(private localStorageService: LocalStorageService) {}

  getToken() {
    this.localStorageService.get(AuthConstants.TOKEN).then((res) => {
      return this.token$.next(res);
    });
  }
}
