import { Component } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader/loader.service';

@Component({
    selector: 'app-progress-spinner',
    templateUrl: './progress-spinner.component.html',
    styleUrls: ['./progress-spinner.component.scss'],
    standalone: false
})
export class ProgressSpinnerComponent {
  constructor(public loader: LoaderService) {}
}
