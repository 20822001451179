<div class="card home-list-pix">
  <h1>Notificações PIX</h1>

  <p-table
    #tablecampaigns
    [value]="campaigns || []"
    [scrollable]="true"
    selectionMode="single"
    scrollHeight="calc(60vh - 10px)"
    (onRowUnselect)="onRowUnselect()"
    [(selection)]="selectedCampaign"
    (onRowSelect)="onRowSelect($event)"
    showGridlines
    stripedRows
    [globalFilterFields]="['id', 'triggerAt', 'value', 'message']"
  >
    <ng-template #caption>
      <div class="table-header-search">
        <p-iconfield iconPosition="left" class="ml-auto">
          <p-inputicon>
            <i class="pi pi-search"></i>
          </p-inputicon>
          <input
            pInputText
            type="text"
            (input)="
              tablecampaigns.filterGlobal($any($event.target).value, 'contains')
            "
            placeholder="Pesquisar"
          />
        </p-iconfield>
      </div>
    </ng-template>

    <ng-template #header>
      <tr>
        <th style="width: 10%" pSortableColumn="id">
          Id<p-sortIcon field="id"></p-sortIcon>
        </th>
        <th style="width: 20%" pSortableColumn="triggerAt">
          Data<p-sortIcon field="triggerAt"></p-sortIcon>
        </th>
        <th style="width: 20%" pSortableColumn="value">
          Valor<p-sortIcon field="value"></p-sortIcon>
        </th>
        <th style="width: 50%" pSortableColumn="message">
          Mensagem<p-sortIcon field="message"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template #body let-campaign>
      <tr>
        <td>{{ campaign?.id }}</td>
        <td>{{ campaign?.triggerAt | date : "dd/MM/yyyy HH:mm:ss" }}</td>
        <td>
          {{ campaign?.value / 100 | currency : "BRL" : "symbol" : "1.2-2" }}
        </td>
        <td>{{ campaign?.message }}</td>
      </tr>
    </ng-template>
    <ng-template #summary>
      <h3>Total: {{ campaigns.length }} campanha(s)</h3>
      <div>
        <button
          class="button-colapp"
          pButton
          pRipple
          label="Cadastrar"
          icon="pi pi-list"
          (click)="showNew = !showNew"
        ></button>
        <button
          pButton
          pRipple
          label="Deletar"
          icon="pi pi-file-edit"
          (click)="onClickDelete()"
        ></button>
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  header="Cadastrar nova campanha"
  [(visible)]="showNew"
  [modal]="true"
  [style]="{ width: '500px', height: '700px' }"
  [draggable]="false"
  [resizable]="false"
  [styleClass]="'p-datatable-sm'"
>
  <form #campaignForm="ngForm">
    <p-iftalabel>
      <input
        pInputText
        id="inMenssage"
        [(ngModel)]="selectedCampaign.message"
        autocomplete="off"
        required
        maxlength="70"
        name="message"
      />
      <label for="inMenssage">Mensagem</label>
    </p-iftalabel>

    <p-iftalabel>
      <p-inputnumber
        id="inValue"
        autocomplete="off"
        required
        name="value"
        [(ngModel)]="value"
        inputId="minmaxfraction"
        mode="decimal"
        [minFractionDigits]="2"
        [maxFractionDigits]="5"
      />
      <label for="inValue">Valor</label>
    </p-iftalabel>

    <p-iftalabel>
      <p-datepicker
        id="inTriggerAt"
        [(ngModel)]="selectedCampaign.triggerAt"
        name="triggerAt"
        dateFormat="dd/mm/yy"
        [showTime]="true"
        hourFormat="24"
        required
      ></p-datepicker>
      <label for="inTriggerAt">Data e Hora</label>
    </p-iftalabel>

    <div class="add-destination">
      <p-autocomplete
        [dropdown]="true"
        [(ngModel)]="pixTypeSelected"
        [suggestions]="filteredPixTypes"
        (completeMethod)="filterPixTypes($event)"
        (onSelect)="onChangePixType($event)"
        optionLabel="name"
        name="pixTypeSelected"
      />
      <input
        type="text"
        placeholder="Adicionar chave PIX"
        [(ngModel)]="newPixKeys"
        name="newPixKey"
        pInputText
      />
      <p-button icon="pi pi-plus" (click)="addDestinations()"></p-button>
    </div>

    <div class="card">
      <label>Chaves Pix</label>
      <p-table
        [value]="selectedCampaign.destinations || []"
        [scrollable]="true"
        scrollHeight="calc(40vh - 10px)"
        showGridlines
        stripedRows
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Tipo de Chave PIX</th>
            <th>Chave PIX</th>
          </tr>
        </ng-template>
        <ng-template let-destination pTemplate="body">
          <tr>
            <td>{{ destination.pixKeyType }}</td>
            <td>{{ destination.pixKey }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <p-footer>
      <p-button
        label="Cadastrar"
        icon="pi pi-check"
        [disabled]="
          !campaignForm.valid ||
          (selectedCampaign.destinations?.length || 0) === 0
        "
        (click)="onClickNew()"
      ></p-button>
    </p-footer>
  </form>
</p-dialog>
