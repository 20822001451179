import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../loader/loader.service';
import { ToastService } from '../toast/toast.service';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public token: any;
  public apiUrl;

  constructor(
    private loaderService: LoaderService,
    private http: HttpClient,
    private tokenService: TokenService,
    private injector: Injector,
    private toastService: ToastService
  ) {
    this.apiUrl = environment.apiUrl;
    this.tokenService.token$.subscribe((res: any) => {
      this.token = res;
    });
  }

  post(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http.post(
      this.apiUrl + serviceName,
      data ? JSON.stringify(data) : null,
      options
    );
  }

  put(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http.put(
      this.apiUrl + serviceName,
      JSON.stringify(data),
      options
    );
  }

  get(serviceName: string, params?: HttpParams) {
    const options = {
      headers: this.getHeader(),
      withCredintials: false,
      params,
    };
    return this.http.get(this.apiUrl + serviceName, options);
  }

  delete(serviceName: string) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http
      .delete(this.apiUrl + serviceName, options)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleAuthError(error))
      );
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      const authService = this.injector.get(AuthService);
      authService.logout();
      this.toastService.info(
        error?.error || 'Ocorreu um erro ao acessar o sistema.',
        ''
      );
      this.loaderService.setLoading(false);
      return EMPTY;
    } else {
      return throwError(() => error);
    }
  }

  getHeader() {
    return new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json')
      .set('Accept-Encondig', 'gzip, deflate, br')
      .set('Authorization', this.token?.token || '');
  }
}
