import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConstants } from '../../config/auth-constants';
import { LocalStorageService } from '../../services/storage/local/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HomeAdminGuard {
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.localStorageService
        .get(AuthConstants.TOKEN)
        .then((res) => {
          if (res) {
            if (res?.user?.admin) {
              resolve(true);
            } else {
              this.router.navigate(['login']);
              resolve(false);
            }
          } else {
            this.router.navigate(['login']);
            resolve(false);
          }
        })
        .catch((_err) => {
          this.router.navigate(['login']);
          resolve(false);
        });
    });
  }
}
