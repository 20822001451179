<app-progress-spinner></app-progress-spinner>
<p-toast position="top-center"></p-toast>
<p-confirmPopup>
  <ng-template #content let-message>
    <div>
      <i [class]="message.icon"></i>
      <p>{{ message.message }}</p>
    </div>
  </ng-template>
</p-confirmPopup>
<router-outlet></router-outlet>
