import { Component } from '@angular/core';

@Component({
    selector: 'app-birthdays',
    templateUrl: './birthdays.component.html',
    styleUrls: ['./birthdays.component.scss'],
    standalone: false
})
export class BirthdaysComponent {

}
