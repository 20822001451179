<div class="card home-transactions-grid">
  <p-table
    [columns]="cols"
    #tabletrans
    [value]="dashboardTransactions.transactions || []"
    styleClass="p-datatable-gridlines"
    [scrollable]="true"
    scrollHeight="calc(60vh - 10px)"
    showGridlines
    stripedRows
    [globalFilterFields]="[
      'currentDate',
      'transactionType',
      'type',
      'user.name',
      'userFrom.name'
    ]"
  >
    <ng-template #caption>
      <div class="table-header-search">
        <div class="icons">
          <p-calendar
            [(ngModel)]="rangeDates"
            (onClickOutside)="onChangeDates()"
            selectionMode="range"
            [readonlyInput]="true"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
          ></p-calendar>
          <p-autocomplete
            [dropdown]="true"
            [(ngModel)]="typeSelected"
            [suggestions]="filteredItems"
            (completeMethod)="filterItems($event)"
            (onSelect)="onChangeTransaction($event)"
            optionLabel="name"
          />
          <p-iconfield iconPosition="left" class="ml-auto">
            <p-inputicon>
              <i class="pi pi-search"></i>
            </p-inputicon>
            <input
              pInputText
              type="text"
              (input)="
                tabletrans.filterGlobal($any($event.target).value, 'contains')
              "
              placeholder="Pesquisar"
            />
          </p-iconfield>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file"
            (click)="tabletrans.exportCSV()"
            class="button-colapp btn-csv"
            pTooltip="Exportar para CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template #header>
      <tr>
        <th pResizableColumn pSortableColumn="currentDate">
          Data<p-sortIcon field="currentDate"></p-sortIcon>
        </th>
        <th pResizableColumn pSortableColumn="trans.user.name">
          Usuário Recebeu<p-sortIcon field="trans.user.name"></p-sortIcon>
        </th>
        <th pResizableColumn pSortableColumn="trans.userFrom.name">
          Usuário Pagou<p-sortIcon field="trans.userFrom.name"></p-sortIcon>
        </th>
        <th pResizableColumn pSortableColumn="originalValue">
          Valor original<p-sortIcon field="originalValue"></p-sortIcon>
        </th>
        <th pResizableColumn pSortableColumn="value">
          Valor<p-sortIcon field="value"></p-sortIcon>
        </th>
        <th pResizableColumn pSortableColumn="type">
          Tipo<p-sortIcon field="type"></p-sortIcon>
        </th>
        <th pResizableColumn pSortableColumn="transactionType">
          Crédito<p-sortIcon field="transactionType"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-trans>
      <tr>
        <td>{{ trans?.currentDate | date : "dd/MM/yyyy HH:mm:ss" }}</td>
        <td class="name">{{ trans?.user?.name }}</td>
        <td class="name">{{ trans?.userFrom?.name }}</td>
        <td>{{ trans?.originalValue / 100 || 0 }}</td>
        <td>{{ trans?.value / 100 }}</td>
        <td>{{ trans?.type }}</td>
        <td>{{ trans?.transactionType }}</td>
      </tr>
    </ng-template>
    <ng-template #summary>
      <h3>
        Total: {{ dashboardTransactions.transactions?.length }} transação(ões)
      </h3>
    </ng-template>
  </p-table>
</div>
