<div class="content">
  <img src="/assets/img/logo/logo.png" alt="" />
  <form>
    <input
      type="email"
      name="email"
      pInputText
      [(ngModel)]="postData.email"
      [ngClass]="{
        'ng-invalid': !postData.email && valid,
        'ng-dirty': !postData.email && valid
      }"
      pInputText
    />
    <small *ngIf="!postData.email && valid" class="p-error block"
      >Informe seu e-mail.</small
    >

    <p-password
      [(ngModel)]="postData.password"
      [toggleMask]="true"
      [feedback]="false"
      name="password"
      promptLabel="Informe sua senha!"
      [ngClass]="{
        'ng-invalid': !postData.password && valid,
        'ng-dirty': !postData.password && valid
      }"
    ></p-password>
    <small *ngIf="!postData.password && valid" class="p-error block"
      >Informe sua senha.</small
    >

    <button class="button-colapp" (click)="onClickLogin()">Login</button>
  </form>
</div>
