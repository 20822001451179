import { Injectable } from '@angular/core';
import { TokenService } from '../../services/token/token.service';

@Injectable({
  providedIn: 'root',
})
export class TokenResolver {
  constructor(private tokenService: TokenService) {}

  resolve() {
    return this.tokenService.getToken();
  }
}
