import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  addSingle(severity: string, summary: string, detail: string) {
    this.messageService.add({ severity, summary, detail, life: 500000 });
  }

  success(summary: string, detail: string) {
    this.addSingle('success', summary, detail);
  }

  error(summary: string, detail: string) {
    this.addSingle('error', summary, detail);
  }

  info(summary: string, detail: string) {
    this.addSingle('info', summary, detail);
  }

  warning(summary: string, detail: string) {
    this.addSingle('warn', summary, detail);
  }

  clear() {
    this.messageService.clear();
  }
}
