import { Component } from '@angular/core';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { CampaignService } from 'src/app/core/services/campaign/campaign.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { PixCampaign } from 'src/app/shared/models/campaign/pix-campaign';
import { PixCampaignDestination } from 'src/app/shared/models/campaign/pix-campaign-destination';

export enum PixType {
  CPF_CNPJ = 1,
  EMAIL = 2,
  PHONE = 3,
  RANDOM_KEY = 4,
}

const PixKeyDescriptions: { [key in PixType]: string } = {
  [PixType.CPF_CNPJ]: 'CPF/CNPJ',
  [PixType.EMAIL]: 'E-mail',
  [PixType.PHONE]: 'Telefone',
  [PixType.RANDOM_KEY]: 'Chave Aleatória',
};

@Component({
  selector: 'app-admin-list-pix',
  templateUrl: './admin-list-pix.component.html',
  styleUrl: './admin-list-pix.component.scss',
  standalone: false,
})
export class AdminListPixComponent {
  public campaigns: PixCampaign[] = [];
  public selectedCampaign: PixCampaign = new PixCampaign();
  public showNew = false;
  public newPixKeys: string = '';
  public pixTypeSelected: any = {};
  public pixTypes: any[] = [];
  public filteredPixTypes: any[] = [];
  public value = 0.01;

  constructor(
    private loaderService: LoaderService,
    private campaignService: CampaignService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    await this.getCampaigns();
    this.getOptionPixTypes();
    this.pixTypeSelected = this.pixTypes?.slice()?.shift();
    this.selectedCampaign = new PixCampaign();
  }

  async getCampaigns() {
    try {
      this.campaigns = [];
      await this.loaderService.setLoading(true);
      this.campaigns = await this.campaignService.list();
    } catch (error) {
      console.error(error);
    } finally {
      await this.loaderService.setLoading(false);
    }
  }

  onRowUnselect() {
    this.selectedCampaign = new PixCampaign();
  }

  onRowSelect(event: any) {
    this.selectedCampaign = event;
    console.log('Campaign selected:', this.selectedCampaign);
  }

  addDestinations() {
    if (!this.selectedCampaign.destinations) {
      this.selectedCampaign.destinations = [];
    }

    if (!this.newPixKeys.trim()) {
      this.toastService.error('Digite uma ou mais chaves PIX válidas', '');
      return;
    }

    const keys = this.newPixKeys
      .split(';')
      .map((key) => key.trim())
      .filter((key) => key);

    if (keys.length === 0) {
      this.toastService.error('Nenhuma chave PIX válida foi informada', '');
      return;
    }

    if (!this.selectedCampaign.destinations) {
      this.selectedCampaign.destinations = [];
    }

    keys.forEach((key) => {
      this.selectedCampaign?.destinations?.push(
        Object.assign(new PixCampaignDestination(), {
          pixKeyType: this.pixTypeSelected.id,
          pixKey: key,
        })
      );
    });

    this.newPixKeys = '';
  }

  async onClickNew() {
    if (!this.isValidCampaign()) {
      this.toastService.error('É necessário preencher todos os campos!', '');
      return;
    }

    try {
      await this.loaderService.setLoading(true);
      this.selectedCampaign.value = this.value * 100;
      await this.campaignService.create(this.selectedCampaign);
      this.toastService.success('Campanha cadastrada com sucesso!', '');
    } catch (error: any) {
      this.toastService.error(error?.toString(), '');
    } finally {
      await this.loaderService.setLoading(false);
      this.getCampaigns();
    }
  }

  private isValidCampaign(): boolean {
    return (
      !!this.selectedCampaign?.message &&
      !!this.value &&
      !!this.selectedCampaign?.triggerAt &&
      (this.selectedCampaign?.destinations?.length || 0) > 0
    );
  }

  async onClickDelete() {
    if (!this.selectedCampaign?.id) {
      this.toastService.error('É necessário selecionar uma campanha!', '');
      return;
    }

    try {
      await this.loaderService.setLoading(true);
      await this.campaignService.delete(this.selectedCampaign.id);
      this.toastService.success('Campanha excluída com sucesso!', '');
    } catch (error: any) {
      this.toastService.error(error?.toString(), '');
    } finally {
      await this.loaderService.setLoading(false);
      this.getCampaigns();
    }
  }

  getOptionPixTypes() {
    this.pixTypes = Object.entries(PixType)
      .filter(([key, value]) => typeof value === 'number')
      .map(([key, id]) => ({
        id: Number(id),
        name: PixKeyDescriptions[id as PixType],
      }));
  }

  onChangePixType(evt: any) {
    this.pixTypeSelected = evt.value;
  }

  filterPixTypes(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    this.filteredPixTypes = this.pixTypes.filter((item) =>
      item?.name?.toLowerCase()?.includes(query)
    );
  }
}
