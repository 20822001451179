import { formatCNPJ, formatCPF } from 'src/app/utils/document';
import { formatPhone } from 'src/app/utils/phone';
import { CompanyFull } from '../company/company-full';
import { Address } from './../address/address';
import { UserBankAccount } from './user-bank-account';
import { UserIndicateds } from './user-indicateds';
import { UserStatements } from './user-statements';

export class User {
  acceptedTerms?: boolean;
  admin?: boolean;
  address?: Address;
  bankAccount?: UserBankAccount;
  birth?: string;
  company?: CompanyFull;
  companyId?: number;
  createdAt?: Date;
  credits = 0;
  document?: string;
  email?: string;
  emailConfirm?: string;
  firstName?: string;
  id?: number;
  indicateds?: UserIndicateds;
  indicator?: string;
  isCompany = false;
  isPrime = false;
  name?: string;
  onHold?: boolean;
  password?: string;
  passwordConfirm?: string;
  phone?: string;
  plan?: string;
  planCreatedAt?: Date;
  statements?: UserStatements;

  fromJson(user: any) {
    if (user) {
      this.admin = user?.admin;
      this.company = user?.companies?.slice()?.shift();
      this.address = new Address();
      this.address.fromJson(
        user?.address || user?.companies?.slice()?.shift()?.address
      );
      this.bankAccount = Object.assign(
        new UserBankAccount(),
        user?.BankAccount?.shift()
      );
      this.birth = user?.birth;
      this.companyId = user?.companies?.slice()?.shift()?.id;
      this.createdAt = user?.createdAt;
      this.document =
        formatCPF(user?.document) ||
        formatCNPJ(user?.companies?.slice()?.shift()?.cnpj);
      this.email = user?.email;
      this.name = user?.name?.toLowerCase();
      this.firstName = user?.name.split(' ').shift();
      this.id = parseFloat(user?.id);
      this.indicateds = new UserIndicateds();
      this.indicator = user?.indicator || '';
      this.isCompany = (this.company && !this.company.disabled) || false;
      this.onHold = user?.onHold;
      this.password = '';
      this.passwordConfirm = '';
      this.phone = formatPhone(user?.phone);
      this.plan = user?.plan?.toUpperCase() === 'PRIME' ? 'PRIME' : 'FREE';
      this.isPrime = this.plan === 'PRIME';
      this.statements = new UserStatements();

      if (user?.UserPlanBilling?.length > 0) {
        this.planCreatedAt = user.UserPlanBilling[0].createdAt;
      }
    }
  }
}
