import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import Aura from '@primeng/themes/aura';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { providePrimeNG } from 'primeng/config';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DatePicker } from 'primeng/datepicker';
import { DialogModule } from 'primeng/dialog';
import { IconField } from 'primeng/iconfield';
import { IftaLabel } from 'primeng/iftalabel';
import { InputIcon } from 'primeng/inputicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MegaMenuModule } from 'primeng/megamenu';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminListPixComponent } from './pages/home/admin/admin-list-pix/admin-list-pix.component';
import { AdminTransactionsComponent } from './pages/home/admin/admin-transactions/admin-transactions.component';
import { AdminUsersComponent } from './pages/home/admin/admin-users/admin-users.component';
import { HomeComponent } from './pages/home/home.component';
import { BirthdaysComponent } from './pages/home/partner/birthdays/birthdays.component';
import { HistoricComponent } from './pages/home/partner/historic/historic.component';
import { PrimeCustomersComponent } from './pages/home/partner/prime-customers/prime-customers.component';
import { SalesComponent } from './pages/home/partner/sales/sales.component';
import { SettingsComponent } from './pages/home/partner/settings/settings.component';
import { SupportComponent } from './pages/home/partner/support/support.component';
import { LoginComponent } from './pages/login/login.component';
import { MenuBarComponent } from './shared/components/menu-bar/menu-bar.component';
import { ProgressSpinnerComponent } from './shared/components/progress-spinner/progress-spinner.component';
registerLocaleData(ptBr);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ProgressSpinnerComponent,
    AdminUsersComponent,
    AdminTransactionsComponent,
    AdminListPixComponent,
    MenuBarComponent,
    SalesComponent,
    BirthdaysComponent,
    PrimeCustomersComponent,
    HistoricComponent,
    SupportComponent,
    SettingsComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    AutoComplete,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CalendarModule,
    CommonModule,
    ConfirmPopupModule,
    ChartModule,
    DatePipe,
    DatePicker,
    DialogModule,
    FormsModule,
    MegaMenuModule,
    InputNumberModule,
    InputTextModule,
    IconField,
    IftaLabel,
    InputIcon,
    PasswordModule,
    ProgressSpinnerModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
  ],
  providers: [
    ConfirmationService,
    MessageService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Aura,
      },
    }),
  ],
})
export class AppModule {}
