import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppInjector } from 'src/app/app.component';
import { User } from 'src/app/shared/models/user/user';
import { AuthConstants } from '../../config/auth-constants';
import { HttpService } from '../http/http.service';
import { AuthService } from './../auth/auth.service';
import { LocalStorageService } from './../storage/local/local-storage.service';
import { ToastService } from './../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userData$ = new BehaviorSubject<any>('');

  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
  ) {}

  getUserData() {
    this.localStorageService.get(AuthConstants.AUTH).then((res) => {
      this.userData$.next(res);
    });
  }

  getUser(): Promise<User> {
    const user: User = new User();
    return new Promise((resolve, reject) => {
      this.httpService.get(`profile/user/`).subscribe({
        next: (res: any) => {
          if (res) {
            user.fromJson(res);
            this.localStorageService.set(AuthConstants.AUTH, user);
            this.userData$.next(user);
            resolve(user);
          }
        },
        error: (error) => {
          const msg =
            error?.error ||
            error?.error?.msg ||
            error?.error?.message ||
            'Ocorreu um erro ao buscar seus dados!';
          this.toastService.error('Erro', msg);
          this.authService.logout();
          reject(msg);
        },
      });
    });
  }

  public get authService(): AuthService {
    return AppInjector.get(AuthService);
  }
}
