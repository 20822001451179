import { Injectable } from '@angular/core';
import { PixCampaign } from 'src/app/shared/models/campaign/pix-campaign';
import { User } from 'src/app/shared/models/user/user';
import { HttpService } from '../http/http.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  public user: User | undefined = new User();

  constructor(
    private httpService: HttpService,
    private toastService: ToastService
  ) {}

  list(): Promise<PixCampaign[]> {
    let campaigns: PixCampaign[] = [];
    return new Promise((resolve, reject) => {
      this.httpService.get('manager/pix-campaign').subscribe({
        next: (res: any) => {
          if (res) {
            res.forEach((element: any) => {
              campaigns.push(Object.assign(new PixCampaign(), element));
            });

            resolve(campaigns);
          }
        },
        error: async (error) => {
          const msg =
            error?.error ||
            error?.error?.msg ||
            error?.error?.message ||
            'Ocorreu um erro ao buscar as campanhas!';
          this.toastService.error('Erro', msg);
          reject(msg);
        },
      });
    });
  }

  create(pixCampaign: PixCampaign): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post('/manager/pix-campaign/schedule', pixCampaign)
        .subscribe({
          next: (res: any) => {
            if (res) {
              resolve(res);
            }
          },
          error: (error) => {
            const msg =
              error?.error?.msg ||
              error?.error?.error ||
              'Ocorreu um erro ao cadastrar capanha.';
            reject(msg);
          },
        });
    });
  }

  delete(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`manager/pix-campaign/${id}/`).subscribe({
        next: (res: any) => {
          res.ok ? resolve(true) : reject(false);
        },
        error: (error) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao deletar a Campanha.';
          reject(msg);
        },
      });
    });
  }
}
