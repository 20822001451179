import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConstants } from '../../config/auth-constants';
import { LocalStorageService } from './../../services/storage/local/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    public localStorageService: LocalStorageService,
    public router: Router
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.localStorageService
        .get(AuthConstants.TOKEN)
        .then((res) => {
          if (res) {
            if (res?.user?.admin) {
              this.router.navigate(['home/admin/usuarios']);
            } else {
              this.router.navigate(['home/parceiro/vendas']);
            }
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((_err) => {
          resolve(true);
        });
    });
  }
}
