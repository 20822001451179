import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/models/user/user';
import { HttpService } from '../http/http.service';
import { ToastService } from '../toast/toast.service';
import { UserService } from '../user/user.service';
import { CompanyFull } from './../../../shared/models/company/company-full';
import { DashboardTransactions } from './../../../shared/models/dashboard/dashboard-transactions';
import { DashboardTransactionsDetails } from './../../../shared/models/dashboard/dashboard-transactions-details';
import { DashboardUsers } from './../../../shared/models/dashboard/dashboard-users';
import { AuthService } from './../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public user: User | undefined = new User();

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.userService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  getAllUser(): Promise<DashboardUsers> {
    let dash: DashboardUsers = new DashboardUsers();
    return new Promise((resolve, reject) => {
      this.httpService.get('manager/dashboard/users/list/').subscribe({
        next: (res: any) => {
          if (res) {
            let newUser: User;
            dash.usersAll = res.map((user: any) => {
              user.companies = user?.companies?.map((company: any) => {
                return Object.assign(new CompanyFull(), company);
              });
              newUser = new User();
              newUser.fromJson(user);
              return newUser;
            });
            dash.usersCompanies = dash?.usersAll?.filter((u) => u?.isCompany);
            dash.usersCompaniesFree = dash?.usersCompanies?.filter(
              (u) => !u?.isPrime
            );
            dash.usersCompaniesPrime = dash?.usersCompanies?.filter(
              (u) => u?.isPrime
            );
            dash.usersNormal = dash?.usersAll?.filter((u) => !u?.isCompany);
            dash.usersNormalFree = dash?.usersNormal?.filter(
              (u) => !u?.isPrime
            );
            dash.usersNormalPrime = dash?.usersNormal?.filter(
              (u) => u?.isPrime
            );
            dash.totalUsersNormalPrime = dash.usersNormalPrime?.length || 0;
            dash.totalUsersNormalFree = dash.usersNormalFree?.length || 0;
            dash.totalUsersCompaniesPrime =
              dash.usersCompaniesPrime?.length || 0;
            dash.totalUsersCompaniesFree = dash.usersCompaniesFree?.length || 0;
            resolve(dash);
          }
        },
        error: async (error) => {
          const msg =
            error?.error ||
            error?.error?.msg ||
            error?.error?.message ||
            'Ocorreu um erro ao buscar os usuário!';
          this.toastService.error('Erro', msg);
          reject(msg);
        },
      });
    });
  }

  allTransactions(
    dtStart: string,
    dtEnd: string,
    type: string
  ): Promise<DashboardTransactions> {
    let dash: DashboardTransactions = new DashboardTransactions();
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`manager/dashboard/transactions/list/${dtStart}/${dtEnd}/${type}/`)
        .subscribe({
          next: (res: any) => {
            if (res?.transactions) {
              const transactions: Array<DashboardTransactionsDetails> = [];
              let newTrans: DashboardTransactionsDetails;
              let newUser: User;
              res.transactions.forEach(
                (trans: DashboardTransactionsDetails) => {
                  newUser = new User();
                  newUser.fromJson(trans.user);
                  trans.user = newUser;

                  newUser = new User();
                  newUser.fromJson(trans.userFrom);
                  trans.userFrom = newUser;

                  newTrans = Object.assign(
                    new DashboardTransactionsDetails(),
                    trans
                  );
                  transactions.push(newTrans);
                }
              );
              dash.transactions = transactions;
              resolve(dash);
            }
          },
          error: (error) => {
            const msg =
              error?.error ||
              error?.error?.msg ||
              error?.error?.message ||
              'Ocorreu um erro ao buscar os usuário!';
            this.toastService.error('Erro', msg);
            this.authService.logout();
            reject(msg);
          },
        });
    });
  }

  getUserIndications(email: String): Promise<User[]> {
    let users: User[] = [];
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`manager/dashboard/user/${email}/indications/`)
        .subscribe({
          next: (res: any) => {
            if (res) {
              let newUser: User;
              res.forEach((user: any) => {
                newUser = new User();
                newUser.fromJson(user);
                users.push(newUser);
              });
            }
            resolve(users);
          },
          error: (error) => {
            const msg =
              error?.error ||
              error?.error?.msg ||
              error?.error?.message ||
              'Ocorreu um erro ao buscar os indicados!';
            this.toastService.error('Erro', msg);
            this.authService.logout();
            reject(msg);
          },
        });
    });
  }

  update(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.put('manager/dashboard/user/', user).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao tentar atualizar o usuário.';
          reject(msg);
        },
      });
    });
  }
}
