import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { CustomConfirmationService } from 'src/app/core/services/confirmation/confirmation.service';
import { DashboardService } from 'src/app/core/services/dashboard/dashboard.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DashboardUsers } from 'src/app/shared/models/dashboard/dashboard-users';
import { User } from 'src/app/shared/models/user/user';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
  standalone: false,
})
export class AdminUsersComponent implements OnInit {
  public colsUser: any[] = [];
  public colsIndications: any[] = [];
  public dashboardSelected: User[] = [];
  public dashboardSelectedIndex = 0;
  public dashboardUsers: DashboardUsers = new DashboardUsers();
  public data: any;
  public selectedUser: User = new User();
  public user: User | undefined = new User();
  public usersIndications: User[] = [];
  public showIndications: boolean = false;
  public showChange: boolean = false;

  constructor(
    private customConfirmationService: CustomConfirmationService,
    private dashboardService: DashboardService,
    private loaderService: LoaderService,
    private userService: UserService,
    private toastService: ToastService
  ) {
    this.userService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  async ngOnInit() {
    this.getDashboardAdminUsers();
  }

  async getDashboardAdminUsers() {
    try {
      await this.loaderService.setLoading(true);
      this.dashboardUsers = new DashboardUsers();
      this.dashboardUsers = await this.dashboardService.getAllUser();
      this.dashboardSelectedIndex = 0;
      this.dashboardSelected = this.getDashboard() || [];
      this.setPie();
    } catch (error) {
      console.error(error);
    } finally {
      await this.loaderService.setLoading(false);
    }
  }

  setPie() {
    this.data = {
      labels: [
        'Usuários Free: ' + this.dashboardUsers.totalUsersNormalFree,
        'Usuários Prime: ' + this.dashboardUsers.totalUsersNormalPrime,
        'Parceiros Free: ' + this.dashboardUsers.totalUsersCompaniesFree,
        'Parceiros Prime: ' + this.dashboardUsers.totalUsersCompaniesPrime,
      ],
      datasets: [
        {
          data: [
            this.dashboardUsers.totalUsersNormalFree,
            this.dashboardUsers.totalUsersNormalPrime,
            this.dashboardUsers.totalUsersCompaniesFree,
            this.dashboardUsers.totalUsersCompaniesPrime,
          ],
          backgroundColor: ['#42A5F5', '#FFA726', '#FF6384', '#66BB6A'],
          hoverBackgroundColor: ['#64B5F6', '#FFB74D', '#fd8ea5', '#81C784'],
        },
      ],
    };

    this.colsUser = [
      { field: 'name', header: 'Nome' },
      { field: 'createdAt', header: 'Cadastro' },
      { field: 'birth', header: 'Nascimento/Abertura' },
      { field: 'address.city.name', header: 'Cidade' },
      { field: 'email', header: 'E-mail' },
    ];

    this.colsIndications = [
      { field: 'name', header: 'Nome' },
      { field: 'email', header: 'E-mail' },
      { field: 'phone', header: 'Telefone' },
    ];
  }

  onDataSelect(event: any) {
    if (event) {
      this.dashboardSelectedIndex = event.element.index;
      this.dashboardSelected = this.getDashboard() || [];
    }
  }

  getDashboard() {
    switch (this.dashboardSelectedIndex) {
      case 0:
        return this.dashboardUsers.usersNormalFree;
      case 1:
        return this.dashboardUsers.usersNormalPrime;
      case 2:
        return this.dashboardUsers.usersCompaniesFree;
      case 3:
        return this.dashboardUsers.usersCompaniesPrime;
      default:
        return [];
    }
  }

  getTitleGrid() {
    switch (this.dashboardSelectedIndex) {
      case 0:
        return 'USUÁRIOS FREE:';
      case 1:
        return 'USUÁRIOS PRIME:';
      case 2:
        return 'PARCEIROS FREE:';
      case 3:
        return 'PARCEIROS PRIME:';
      default:
        return 'Dado não mapeado:';
    }
  }

  onRowUnselect() {
    this.selectedUser = new User();
  }

  async onClickIndications() {
    if (!this.selectedUser?.id) {
      this.toastService.error('É necessário selecionar um usuário!', '');
      return;
    }

    try {
      await this.loaderService.setLoading(true);
      this.usersIndications = await this.dashboardService.getUserIndications(
        this.selectedUser.email || ''
      );

      if (this.usersIndications.length > 0) {
        this.showIndications = true;
      } else {
        this.toastService.warning('Usuário não possui indicações!', '');
      }
    } catch (error) {
      console.error(error);
    } finally {
      await this.loaderService.setLoading(false);
    }
  }

  onClickChange() {
    if (!this.selectedUser?.id) {
      this.toastService.error('É necessário selecionar um usuário!', '');
      return;
    }
    this.showChange = true;
  }

  clear(table: Table) {
    table.clear();
  }

  onClickChangeEmail(event: Event) {
    if (!this.selectedUser?.email) {
      this.toastService.error('É necessário preencher um e-mail!', '');
      return;
    }
    this.customConfirmationService.confirm({
      target: event.target || undefined,
      message: 'Deseja realmente alterar o e-mail?',
      acceptCallback: async () => {
        await this.updateUser();
      },
    });
  }

  onClickConfirmUser(event: Event) {
    this.customConfirmationService.confirm({
      target: event.target || undefined,
      message: 'Deseja realmente confirmar esse usuário?',
      acceptCallback: async () => {
        this.selectedUser.onHold = false;
        await this.updateUser();
      },
    });
  }

  async updateUser() {
    try {
      await this.loaderService.setLoading(true);
      await this.dashboardService.update(this.selectedUser);
      this.toastService.success('Usuário alterado com sucesso!', '');
      this.showChange = false;
      this.selectedUser = new User();
    } catch (error: any) {
      this.toastService.error(error, '');
    } finally {
      await this.loaderService.setLoading(false);
    }
  }
}
