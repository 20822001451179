import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeAdminGuard } from './core/guards/home-admin/home-admin.guard';
import { LoginGuard } from './core/guards/login/login.guard';
import { TokenResolver } from './core/resolvers/token/token.resolver';
import { UserResolver } from './core/resolvers/user/user.resolver';
import { AdminListPixComponent } from './pages/home/admin/admin-list-pix/admin-list-pix.component';
import { AdminTransactionsComponent } from './pages/home/admin/admin-transactions/admin-transactions.component';
import { AdminUsersComponent } from './pages/home/admin/admin-users/admin-users.component';
import { HomeComponent } from './pages/home/home.component';
import { BirthdaysComponent } from './pages/home/partner/birthdays/birthdays.component';
import { HistoricComponent } from './pages/home/partner/historic/historic.component';
import { PrimeCustomersComponent } from './pages/home/partner/prime-customers/prime-customers.component';
import { SalesComponent } from './pages/home/partner/sales/sales.component';
import { SettingsComponent } from './pages/home/partner/settings/settings.component';
import { SupportComponent } from './pages/home/partner/support/support.component';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    resolve: {
      token: TokenResolver,
      user: UserResolver,
    },
    children: [
      {
        path: 'admin/transacoes',
        component: AdminTransactionsComponent,
        canActivate: [HomeAdminGuard],
      },
      {
        path: 'admin/usuarios',
        component: AdminUsersComponent,
        canActivate: [HomeAdminGuard],
      },
      {
        path: 'admin/lista-pix',
        component: AdminListPixComponent,
        canActivate: [HomeAdminGuard],
      },
      {
        path: 'parceiro/vendas',
        component: SalesComponent,
      },

      {
        path: 'parceiro/aniversariantes',
        component: BirthdaysComponent,
      },
      {
        path: 'parceiro/clientes-prime',
        component: PrimeCustomersComponent,
      },
      {
        path: 'parceiro/historico',
        component: HistoricComponent,
      },
      {
        path: 'parceiro/suporte',
        component: SupportComponent,
      },
      {
        path: 'parceiro/configuracoes',
        component: SettingsComponent,
      },
    ],
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    component: LoginComponent,
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
